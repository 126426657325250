<template>
  <div>
    <breadcrumb-bar
      :title="appTitle"
      :breadcrumbs="breadcrumbs"
      :titleclass="pagestyles.breadcrumb"
      :titlecolor="pagestyles.breadcrumbColor"
    />
    <v-container
      fluid
    >
      <v-form>
        <v-row>
          <v-col
            cols="12"
            lg="7"
            md="12"
            sm="12"
          >
            <base-material-card
              :color="pagestyles.detailFrameColor"
              icon="mdi-tanker-truck"
              class="px-4 py-2"
            >
              <template v-slot:after-heading>
                <div
                  class="display-2 font-weight-bold"
                  :style="`color: ${pagestyles.detailFrameHeading}`"
                >
                  {{ $t('movementTransactionDetails') }}
                </div>
              </template>
              <v-card-text>
                <v-row
                  dense
                >
                  <v-col
                    cols="2"
                    lg="2"
                    md="4"
                    sm="6"
                  >
                    <v-text-field
                      v-if="movement.TransactionId != 0"
                      v-model="movement.TransactionId"
                      :label="$t('movementLabelTransactionId')"
                      readonly
                      dense
                    />
                    <v-text-field
                      v-else
                      :label="$t('movementLabelTransactionId')"
                      readonly
                      value="New"
                      dense
                    />
                  </v-col>
                  <v-col
                    cols="3"
                    lg="3"
                    md="4"
                    sm="6"
                  >
                    <v-text-field
                      v-model="movement.TransactionDate"
                      type="Date"
                      :error-messages="dateErrors"
                      :label="$t('movementLabelTransactionDate')"
                      dense
                      @input="$v.movement.TransactionDate.$touch()"
                      @blur="$v.movement.TransactionDate.$touch()"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    lg="7"
                    md="12"
                    sm="12"
                  >
                    <v-select
                      v-model="movement.TargetPartner"
                      :items="targetPartners"
                      item-text="name"
                      item-value="partnerId"
                      :error-messages="targetPartnerErrors"
                      :label="labelPartner"
                      :return-object="boolTrue"
                      :disabled="targetPartnerDisabled"
                      dense
                      @input="$v.movement.TargetPartnerId.$touch()"
                      @blur="$v.movement.TargetPartnerId.$touch()"
                      @change="onTargetPartnerChange"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                  >
                    <v-select
                      v-if="showOriginListingProducts"
                      v-model="movement.OriginListing"
                      :items="products"
                      item-text="productAlias"
                      item-value="listingId"
                      :error-messages="originProductErrors"
                      :label="this.$t('movementLabelOriginProduct')"
                      :return-object="boolTrue"
                      dense
                      @input="$v.movement.OriginListingId.$touch()"
                      @blur="$v.movement.OriginListingId.$touch()"
                      @change="onOriginListingChange"
                    />
                    <v-select
                      v-if="showOriginListingProducts == false"
                      v-model="movement.OriginCommitment"
                      :items="commitments"
                      item-text="detailDescription"
                      item-value="lineNumber"
                      :error-messages="originCommitmentErrors"
                      :label="this.$t('movementLabelCommitment')"
                      :return-object="boolTrue"
                      dense
                      @input="$v.movement.OriginListingId.$touch()"
                      @blur="$v.movement.OriginListingId.$touch()"
                      @change="onOriginCommitmentChange"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="6"
                    lg="3"
                    md="6"
                    sm="6"
                  >
                    <v-text-field
                      v-model="movement.OriginQuantity"
                      type="Number"
                      :error-messages="originQuantityErrors"
                      :label="$t('movementLabelTransactionQty')"
                      dense
                      @input="$v.movement.OriginQuantity.$touch()"
                      @blur="$v.movement.OriginQuantity.$touch()"
                      @change="onChangeReload"
                    />
                  </v-col>
                  <v-col
                    cols="6"
                    lg="3"
                    md="6"
                    sm="6"
                  >
                    <v-select
                      v-model="movement.UnitofMeasure"
                      :items="units"
                      item-text="name"
                      item-value="unitId"
                      :error-messages="originUnitErrors"
                      class="purple-input"
                      :label="this.$t('movementLabelOriginUnit')"
                      dense
                      @input="$v.movement.UnitofMeasure.$touch()"
                      @blur="$v.movement.UnitofMeasure.$touch()"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </base-material-card>
            <base-material-card
              :color="pagestyles.documentFrameColor"
              icon="mdi-file-document-outline"
              class="px-4 py-2"
            >
              <template v-slot:after-heading>
                <div
                  class="display-2 font-weight-bold"
                  :style="`color: ${pagestyles.detailFrameHeading}`"
                >
                  {{ $t('movementTransactionDocuments') }}
                </div>
              </template>
              <v-card-text>
                <v-data-table
                  :headers="referenceHeaders"
                  :items="movement.documents"
                  item-key="ReferenceType"
                  hide-default-footer
                  :items-per-page="movement.documents.length"
                  single-select
                  class="elevation-1"
                >
                  <template v-slot:item.ReferenceNumber="{ item }">
                    <v-text-field
                      v-model="item.ReferenceNumber"
                      class="mt-0 pt-0"
                      hide-details
                      single-line
                      dense
                    />
                  </template>
                </v-data-table>
              </v-card-text>
            </base-material-card>
          </v-col>
          <v-col
            cols="12"
            lg="5"
            md="12"
            sm="12"
          >
            <base-material-card
              :color="pagestyles.valueChainFrameColor"
              class="px-4 py-2"
            >
              <template v-slot:heading>
                <div
                  class="display-2 font-weight-bold"
                >
                  {{ $t('movementTransactionValueChain') }}
                  <v-menu
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-if="showAddValueChain"
                        absolute
                        dark
                        fab
                        top
                        right
                        small
                        :color="pagestyles.valueChainAddColor"
                        v-on="on"
                        @click="onAddNewValueChain"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                  </v-menu>
                </div>
              </template>
              <v-timeline>
                <v-timeline-item
                  v-for="(item) in movement.valueChain"
                  :key="item.partnerId"
                  :color="pagestyles.valueChainItemColor"
                  large
                >
                  <template v-slot:opposite>
                    <span
                      class="display-1 font-weight-bold"
                    >
                      {{ item.PartnerName }}
                    </span>
                  </template>
                </v-timeline-item>
              </v-timeline>
            </base-material-card>
            <buy-back
              v-if="showBuyback"
              :key="reloadBuyback"
              :listing-id="computedListingId"
              :partner-id="computedBuybackPartnerId"
              :quantity="computedQuantity"
            />
            <commitment-details
              v-if="showCommitment"
              :key="reloadBuyback"
              :group-id="computedGroupId"
              :partner-id="computedValuePartnerId"
              :quantity="computedQuantity"
            />
            <inventory-details
              v-if="showInventory"
              :key="reloadInventory"
              :data-type="inventoryType"
              :product-id="computedGroupId"
            />
          </v-col>
        </v-row>
        <v-row
          class="white"
        >
          <v-col
            cols="12"
          >
            <v-btn
              color="success"
              :disabled="disableSubmit"
              @click="onMovementCreate"
            >
              {{ buttonSubmitText }}
            </v-btn>
            <v-btn
              text
              to="/dashboard"
            >
              {{ $t('buttonClose') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <v-dialog
      :value="showValueChainDialog"
      persistent
      width="25%"
    >
      <v-card>
        <v-card-title>
          {{ $t('movementSelectValueChainPartner') }}
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="valueChainPartner"
            :items="valueChainPartners"
            item-text="name"
            item-value="partnerId"
            class="purple-input"
            dense
            hint="Partner to add"
            :return-object="boolTrue"
            :persistent-hint="boolTrue"
          />
          <v-select
            v-model="addAfterPartnerId"
            :items="partners"
            item-text="name"
            item-value="partnerId"
            class="purple-input"
            dense
            hint="Add after"
            :persistent-hint="boolTrue"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="success"
            @click="onAddValueChainDialogOk"
          >
            {{ this.$t('buttonOK') }}
          </v-btn>
          <v-btn
            color="error"
            text
            @click="showValueChainDialog = false"
          >
            {{ this.$t('buttonClose') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <error-dialog
      dialog-type="error"
      :dialog="showErrorDialog"
      :title="$t('errorTitleProcess')"
      :message="errorMessage"
      @update:dialog="showErrorDialog = false"
    />
    <process-overlay
      :processing="modelProcessing"
    />
  </div>
</template>
<script>
  import { mapFields } from 'vuex-map-fields'
  import { required, helpers } from 'vuelidate/lib/validators'
  import productAPI from '@/services/productServices'
  import masterAPI from '@/services/masterData'
  import transactionAPI from '@/services/transaction'
  import dashboardAPI from '@/services/dashboard'
  import i18n from '@/i18n'
  import Vue from 'vue'
  import customColors from '@/styles/appcolors'
  const notZero = (value) => !helpers.req(value) || value > 0
  function initialState () {
    return {
      appTitle: '',
      breadcrumbs: [
        {
          text: i18n.t('bcHome'),
          disabled: false,
          href: '/dashboard',
        },
        {
          text: i18n.t('bcMaterial'),
          disabled: true,
          href: '/material',
        },
        {
          text: i18n.t('bcMovement'),
          disabled: false,
          href: '/material/movement',
        },
      ],
      pagestyles: {
        breadcrumb: customColors.MainPage.breadcrumb,
        breadcrumbColor: customColors.MainPage.breadcrumbColor,
        detailFrameColor: customColors.Transaction.detailFrameColor,
        detailFrameHeading: customColors.Transaction.detailFrameHeading,
        documentFrameColor: customColors.Transaction.documentFrameColor,
        documentTableBackground: customColors.Transaction.documentTableBackground,
        valueChainFrameColor: customColors.Transaction.valueChainFrameColor,
        valueChainAddColor: customColors.Transaction.valueChainAddColor,
        valueChainItemColor: customColors.Transaction.valueChainItemColor,
        commitmentFrameColor: customColors.Transaction.commitmentFrameColor,
      },
      boolTrue: true,
      boolFalse: false,
      targetPartnerDisabled: false,
      showAddValueChain: true,
      showErrorDialog: false,
      errorMessage: '',
      modelProcessing: false,
      movement: {
        TransactionId: 0,
        RelatedTransactionId: 0,
        TransactionType: 1,
        TransactionDate: Vue.moment(new Date(), 'yyyy-MM-dd').format().substring(0, 10),
        OriginPartnerId: 0,
        TargetPartner: null,
        TargetPartnerId: 0,
        CommitmentPartnerId: 0,
        OriginProductId: 0,
        TargetProductId: 0,
        OriginListing: null,
        OriginListingId: 0,
        OriginCommitment: null,
        TargetListingId: 0,
        OriginQuantity: 0,
        TargetQuantity: 0,
        UnitofMeasure: 1,
        StatusCode: 11,
        documents: [],
        valueChain: [],
      },
      targetPartners: [],
      disableAddValueChain: false,
      valueChainPartners: [],
      valueChainPartner: null,
      valueChainPartnerId: 0,
      addAfterPartnerId: 0,
      valueChainPartnerName: 'Have to Add',
      products: [],
      commitments: [],
      units: [],
      productType: 0,
      labelPartner: i18n.t('movementLabelTargetPartner'),
      referenceHeaders: [
        {
          text: i18n.t('colReferenceType'),
          align: 'start',
          value: 'ReferenceText',
          sortable: false,
          class: 'info display-1 font-weight-bold',
        },
        {
          text: i18n.t('colReferenceNumber'),
          align: 'start',
          value: 'ReferenceNumber',
          sortable: false,
          width: '40%',
          class: 'info display-1 font-weight-bold',
        },
      ],
      showValueChainDialog: false,
      showBuyback: false,
      showCommitment: false,
      showOriginListingProducts: true,
      reloadBuyback: 0,
      showInventory: false,
      reloadInventory: 0,
      inventoryType: 3,
      buttonSubmitText: i18n.t('buttonCreate'),
    }
  }
  export default {
    Name: 'Movement',
    components: {
      BreadcrumbBar: () => import('@/views/components/core/BreadcrumbBar'),
      ErrorDialog: () => import('@/views/components/core/ErrorDialog'),
      ProcessOverlay: () => import('@/views/components/core/Overlay'),
      BuyBack: () => import('./component/BuyBack'),
      CommitmentDetails: () => import('./component/Commitment'),
      InventoryDetails: () => import('./component/Inventory'),
    },
    props: {
      type: {
        type: String,
        default: '1',
      },
      transactionId: {
        type: String,
        default: '0',
      },
      crtype: {
        type: String,
        default: 'C',
      },
    },
    data: function () {
      return initialState()
    },
    validations: {
      movement: {
        TransactionDate: {
          required,
        },
        TargetPartnerId: {
          required,
          notZero,
        },
        OriginListingId: {
          required,
          notZero,
        },
        OriginQuantity: {
          required,
          notZero,
        },
        UnitofMeasure: {
          required,
          notZero,
        },
      },
    },
    computed: {
      ...mapFields({
        currentUser: 'currentUser',
        documentTypes: 'documentTypes',
        partners: 'partners',
      }),
      disableSubmit: function () {
        return this.$v.$invalid
      },
      computedListingId: function () {
        return Number(this.movement.OriginListingId)
      },
      computedGroupId: function () {
        return Number(this.movement.OriginProductId)
      },
      computedBuybackPartnerId: function () {
        let valuePartnerId = 0
        if (this.movement.valueChain.length > 0) {
          valuePartnerId = this.movement.valueChain[0].PartnerId
        }
        return Number(valuePartnerId)
      },
      computedValuePartnerId: function () {
        let valuePartnerId = 0
        if (this.movement.TransactionType === 21) {
          valuePartnerId = this.movement.TargetPartnerId
        } else if (this.movement.valueChain.length > 1) {
          valuePartnerId = this.movement.valueChain[this.movement.valueChain.length - 1].PartnerId
        }
        return Number(valuePartnerId)
      },
      computedQuantity: function () {
        return Number(this.movement.OriginQuantity)
      },
      dateErrors: function () {
        const errors = []
        if (!this.$v.movement.TransactionDate.$dirty) return errors
        !this.$v.movement.TransactionDate.required && errors.push(this.$t('movementTransactionDateRequired'))
        return errors
      },
      targetPartnerErrors: function () {
        const errors = []
        if (!this.$v.movement.TargetPartnerId.$dirty) return errors
        !this.$v.movement.TargetPartnerId.required && errors.push(this.$t('movementTargetPartnerRequired'))
        !this.$v.movement.TargetPartnerId.notZero && errors.push(this.$t('movementTargetPartnerRequired'))
        return errors
      },
      originProductErrors: function () {
        const errors = []
        if (!this.$v.movement.OriginListingId.$dirty) return errors
        !this.$v.movement.OriginListingId.required && errors.push(this.$t('movementOriginProductRequired'))
        !this.$v.movement.OriginListingId.notZero && errors.push(this.$t('movementOriginProductRequired'))
        return errors
      },
      originCommitmentErrors: function () {
        const errors = []
        if (!this.$v.movement.OriginListingId.$dirty) return errors
        !this.$v.movement.OriginListingId.required && errors.push(this.$t('movementOriginCommitmentRequired'))
        !this.$v.movement.OriginListingId.notZero && errors.push(this.$t('movementOriginCommitmentRequired'))
        return errors
      },
      originQuantityErrors: function () {
        const errors = []
        if (!this.$v.movement.OriginQuantity.$dirty) return errors
        !this.$v.movement.OriginQuantity.required && errors.push(this.$t('movementOriginQuantityRequired'))
        !this.$v.movement.OriginQuantity.notZero && errors.push(this.$t('movementOriginQuantityRequired'))
        return errors
      },
      originUnitErrors: function () {
        const errors = []
        if (!this.$v.movement.UnitofMeasure.$dirty) return errors
        !this.$v.movement.UnitofMeasure.required && errors.push(this.$t('movementUnitRequired'))
        !this.$v.movement.UnitofMeasure.notZero && errors.push(this.$t('movementUnitRequired'))
        return errors
      },
    },
    mounted: function () {
      if (Number(this.transactionId) !== 0) {
        // have a transaction
        this.modelProcessing = true
        transactionAPI.getMaterialMovement(this.transactionId)
          .then(response => {
            this.initializeForm(response.data)
            this.modelProcessing = false
          })
          .catch(error => {
            this.showErrorDialog = true
            this.errorMessage = error.message
            this.modelProcessing = false
          })
      } else {
        this.initializeForm(null)
      }
    },
    methods: {
      initializeForm: function (transactionData) {
        switch (this.crtype) {
          case 'C':
            this.movement.TransactionId = 0
            this.movement.OriginPartnerId = Number(this.currentUser.partnerId)
            if (transactionData != null) {
              switch (transactionData.transactionType) {
                case 21:
                  this.movement.TransactionType = 1
                  this.movement.RelatedTransactionId = transactionData.transactionId
                  if (transactionData.sourceValueChain !== null) {
                    if (transactionData.sourceValueChain.length > 1) {
                      this.movement.TargetPartnerId = transactionData.sourceValueChain[1].partnerId
                      this.targetPartnerDisabled = true
                      this.productType = transactionData.originProductId
                      this.showAddValueChain = false
                    }
                  }
                  this.movement.OriginQuantity = transactionData.originQuantity
                  this.movement.TargetQuantity = transactionData.originQuantity
                  this.movement.UnitofMeasure = transactionData.unitofMeasure
                  this.movement.UnitofMeasureText = transactionData.unitofMeasureText
                  break
              }
            } else {
              this.movement.TransactionType = Number(this.type)
              this.productType = 1
            }
            break
          case 'U':
            // copy values
            this.movement.TransactionId = transactionData.transactionId
            this.movement.TransactionType = transactionData.transactionType
            this.movement.TransactionDate = Vue.moment(Vue.moment(transactionData.transactionDate), 'yyyy-MM-dd').format().substring(0, 10)
            this.movement.OriginPartnerId = transactionData.originPartnerId
            this.movement.TargetPartnerId = transactionData.targetPartnerId
            this.movement.OriginProductId = transactionData.originProductId
            this.movement.OriginListingId = transactionData.originListingId
            this.movement.OriginQuantity = transactionData.originQuantity
            this.movement.UnitofMeasure = transactionData.unitofMeasure
            this.movement.UnitofMeasureText = transactionData.unitofMeasureText
            this.movement.documents = []
            for (const item of transactionData.documents) {
              const doc = {
                TransactionId: transactionData.transactionId,
                LineNumber: item.lineNumber,
                ReferenceType: item.referenceType,
                ReferenceText: item.referenceTypeText,
                ReferenceNumber: item.referenceNumber,
              }
              this.movement.documents.push(doc)
            }
            this.movement.valueChain = []
            for (const item of transactionData.sourceValueChain) {
              const valc1 = {
                TransactionId: transactionData.transactionId,
                LineNumber: item.lineNumber,
                PartnerId: item.partnerId,
                PartnerName: item.partnerName,
              }
              this.movement.valueChain.push(valc1)
            }
            this.targetPartnerDisabled = true
            this.productType = 1
            this.buttonSubmitText = this.$t('buttonSave')
            break
        }
        switch (this.movement.TransactionType) {
          case 1:
            this.appTitle = this.$t('titleTransactionMaterialShipped') + this.currentUser.partnerName
            this.showBuyback = false
            this.showCommitment = true
            break
          case 12:
            this.appTitle = this.$t('titleTransactionWasteSent') + this.currentUser.partnerName
            this.productType = 21
            this.labelPartner = this.$t('movementLabelSendWasteto')
            this.showBuyback = true
            this.showCommitment = false
            break
          case 14:
            this.appTitle = this.$t('titleTransactionWasteSent') + this.currentUser.partnerName
            this.productType = 21
            this.labelPartner = this.$t('movementLabelSendWasteto')
            this.showBuyback = false
            this.showCommitment = false
            this.showAddValueChain = false
            this.showInventory = true
            break
          case 21:
            this.appTitle = this.$t('titleTransactionRequestProduct') + this.currentUser.partnerName
            this.productType = 11
            this.labelPartner = this.$t('movementLabelRequestProductFrom')
            this.showBuyback = false
            this.showCommitment = true
            break
          case 31:
            this.appTitle = this.$t('titleTransactionAssignCommitment') + this.currentUser.partnerName
            this.productType = 1
            this.labelPartner = this.$t('movementLabelAssignCommitmentto')
            this.showBuyback = false
            this.showCommitment = false
            this.showOriginListingProducts = false
            this.showAddValueChain = false
            break
        }
        this.modelProcessing = true
        if (this.partners.length === 0) {
          this.$store.dispatch('GET_PARTNERS')
            .then(response => {
              this.updateTargetPartners()
              if (this.crtype === 'C') {
                this.updateValueChain()
              }
            })
        } else {
          this.updateTargetPartners()
          if (this.crtype === 'C') {
            this.updateValueChain()
          }
        }
        masterAPI.getUnits()
          .then(response => {
            this.units = response.data
          })
        if (this.documentTypes === null) {
          this.$store.dispatch('GET_DOCUMENT_TYPES')
            .then(response => {
              this.updateDocuments()
            })
        } else if (this.documentTypes.length === 0) {
          this.$store.dispatch('GET_DOCUMENT_TYPES')
            .then(response => {
              this.updateDocuments()
            })
        } else {
          this.updateDocuments()
        }
        switch (this.movement.TransactionType) {
          case 31:
            dashboardAPI.getDashboardCommitmentDetailsAvailable(this.productType)
              .then(response => {
                this.modelProcessing = false
                this.commitments = response.data.details
                if (this.crtype === 'U') {
                  for (const item of this.commitments) {
                    if (item.groupId === this.movement.OriginProductId) {
                      this.movement.OriginCommitment = item
                      this.movement.OriginListingId = this.movement.OriginCommitment.groupId
                      this.movement.CommitmentPartnerId = this.movement.OriginCommitment.partnerId
                      break
                    }
                  }
                }
              })
              .catch(error => {
                this.showErrorDialog = true
                this.errorMessage = error.message
                this.modelProcessing = false
              })
            break
          default:
            productAPI.getProductListingsByProductType(this.productType)
              .then(response => {
                this.modelProcessing = false
                this.products = response.data
                if (this.crtype === 'U') {
                  for (const item of this.products) {
                    if (item.listingId === this.movement.OriginListingId) {
                      this.movement.OriginListing = item
                      break
                    }
                  }
                }
              })
              .catch(error => {
                this.showErrorDialog = true
                this.errorMessage = error.message
                this.modelProcessing = false
              })
            break
        }
      },
      updateTargetPartners: function () {
        this.targetPartners = []
        for (const item of this.partners) {
          if (this.currentUser.partnerId !== item.partnerId) {
            switch (this.movement.TransactionType) {
              case 12:
                if (item.partnerType === 1 || item.partnerType === 16) {
                  this.targetPartners.push(item)
                }
                break
              case 14:
                if (item.partnerType === 1) {
                  this.targetPartners.push(item)
                }
                break
              case 31:
                if (item.partnerType !== 1 && item.partnerType !== 16) {
                  this.targetPartners.push(item)
                }
                break
              default:
                this.targetPartners.push(item)
                break
            }
            if (this.movement.TargetPartnerId === item.partnerId) {
              this.movement.TargetPartner = item
            }
          }
        }
      },
      updateValueChain: function () {
        this.movement.valueChain = []
        for (const item of this.partners) {
          switch (this.movement.TransactionType) {
            case 1:
            case 31:
              if (this.currentUser.partnerId === item.partnerId) {
                const valc1 = {
                  TransactionId: 0,
                  LineNumber: 1,
                  PartnerId: Number(item.partnerId),
                  PartnerName: item.name,
                }
                this.movement.valueChain.push(valc1)
              }
              break
            default:
              if (Number(item.partnerType) === 1) {
                const valc = {
                  TransactionId: 0,
                  LineNumber: 1,
                  PartnerId: Number(item.partnerId),
                  PartnerName: item.name,
                }
                this.movement.valueChain.push(valc)
              }
              break
          }
        }
        if (this.movement.TransactionType === 1 && this.movement.RelatedTransactionId !== 0) {
          const target1 = {
            TransactionId: 0,
            LineNumber: this.movement.valueChain.length + 1,
            PartnerId: Number(this.movement.TargetPartner.partnerId),
            PartnerName: this.movement.TargetPartner.name,
          }
          this.movement.valueChain.push(target1)
        } else if (this.movement.TransactionType === 12 || this.movement.TransactionType === 14 || this.movement.TransactionType === 21) {
          const target = {
            TransactionId: 0,
            LineNumber: this.movement.valueChain.length + 1,
            PartnerId: Number(this.currentUser.partnerId),
            PartnerName: this.currentUser.partnerName,
          }
          this.movement.valueChain.push(target)
        }
      },
      updateDocuments: function () {
        if (this.crtype === 'C') {
          this.movement.documents = []
          let i = 1
          for (const item of this.documentTypes) {
            switch (this.movement.TransactionType) {
              case 1:
                if (Number(item.key) === 1 || Number(item.key) === 3 || Number(item.key) === 4 || Number(item.key) === 5) {
                  const doc = {
                    TransactionId: 0,
                    LineNumber: i,
                    ReferenceType: Number(item.key),
                    ReferenceText: item.value,
                    ReferenceNumber: '',
                  }
                  this.movement.documents.push(doc)
                  i++
                }
                break
              case 12:
              case 14:
              case 21:
                // send waste
                if (Number(item.key) === 3 || Number(item.key) === 5) {
                  const doc = {
                    TransactionId: 0,
                    LineNumber: i,
                    ReferenceType: Number(item.key),
                    ReferenceText: item.value,
                    ReferenceNumber: '',
                  }
                  this.movement.documents.push(doc)
                  i++
                }
                break
              case 31:
                if (Number(item.key) === 3) {
                  const doc = {
                    TransactionId: 0,
                    LineNumber: i,
                    ReferenceType: Number(item.key),
                    ReferenceText: item.value,
                    ReferenceNumber: '',
                  }
                  this.movement.documents.push(doc)
                  i++
                }
                break
              default:
                break
            }
          }
        }
      },
      onAddNewValueChain: function () {
        this.valueChainPartners = []
        for (const item of this.partners) {
          // verify if this partner is already added
          let partnerAdded = false
          if (item.partnerType !== 16) {
            for (const valueItem of this.movement.valueChain) {
              if (valueItem.PartnerId === item.partnerId) {
                partnerAdded = true
                break
              }
            }
          }
          if (!partnerAdded) {
            this.valueChainPartners.push(item)
          }
        }
        if (this.valueChainPartners.length !== 0) {
          this.showValueChainDialog = true
        } else {
          this.disableAddValueChain = true
        }
      },
      onMovementCreate: function () {
        this.modelProcessing = true
        transactionAPI.createMaterialMovement(this.movement)
          .then(response => {
            this.modelProcessing = false
            // verify if error in response
            if (response.data != null) {
              switch (response.data.type) {
                case 1:
                  // error
                  this.errorMessage = response.data.errorMessage
                  this.showErrorDialog = true
                  break
                default:
                  Object.assign(this.$data, initialState())
                  this.$router.push('/dashboard')
                  break
              }
            }
          })
          .catch(error => {
            this.errorMessage = error.message
            this.showErrorDialog = true
            this.modelProcessing = false
          })
      },
      onAddValueChainDialogOk: function () {
        const valueChain = []
        this.modelProcessing = true
        let partnerAdded = false
        this.valueChainPartnerId = this.valueChainPartner.partnerId
        this.valueChainPartnerName = this.valueChainPartner.name
        if (Number(this.valueChainPartnerId) !== 0 && Number(this.addAfterPartnerId) !== 0) {
          for (const valueItem of this.movement.valueChain) {
            if (valueItem.PartnerId === Number(this.valueChainPartnerId)) {
              partnerAdded = true
              break
            }
          }
          if (!partnerAdded) {
            let lineNumber = 1
            for (const valueItem of this.movement.valueChain) {
              if (Number(valueItem.PartnerId) === Number(this.addAfterPartnerId)) {
                valueItem.LineNumber = lineNumber
                valueChain.push(valueItem)
                lineNumber++
                const valc = {
                  TransactionId: 0,
                  LineNumber: lineNumber,
                  PartnerId: Number(this.valueChainPartnerId),
                  PartnerName: this.valueChainPartnerName,
                }
                valueChain.push(valc)
                partnerAdded = true
              } else {
                valueItem.LineNumber = lineNumber
                valueChain.push(valueItem)
              }
              lineNumber++
            }
            if (!partnerAdded) {
              const valc = {
                TransactionId: 0,
                LineNumber: lineNumber,
                PartnerId: Number(this.valueChainPartnerId),
                PartnerName: this.valueChainPartnerName,
              }
              valueChain.push(valc)
            }
            this.movement.valueChain = valueChain
          }
        }
        this.showValueChainDialog = false
        this.modelProcessing = false
        this.reloadBuyback++
      },
      onTargetPartnerChange: function () {
        this.movement.TargetPartnerId = this.movement.TargetPartner.partnerId
        if (this.movement.TransactionType === 1 || this.movement.TransactionType === 31) {
          this.updateValueChain()
          const target = {
            TransactionId: 0,
            LineNumber: this.movement.valueChain.length + 1,
            PartnerId: Number(this.movement.TargetPartnerId),
            PartnerName: this.movement.TargetPartner.name,
          }
          this.movement.valueChain.push(target)
        } else if (this.movement.TransactionType === 12) {
          this.updateValueChain()
          if (this.movement.TargetPartner.partnerType === 16) {
            // Waste Aggregator selected - add if it doesn't exists
            const valueChain = []
            let lineNumber = 1
            for (const valueItem of this.movement.valueChain) {
              if (lineNumber === 1) {
                // first item
                valueItem.LineNumber = lineNumber
                valueChain.push(valueItem)
                lineNumber++
                const valc = {
                  TransactionId: 0,
                  LineNumber: lineNumber,
                  PartnerId: this.movement.TargetPartner.partnerId,
                  PartnerName: this.movement.TargetPartner.name,
                }
                valueChain.push(valc)
              } else {
                valueItem.LineNumber = lineNumber
                valueChain.push(valueItem)
              }
              lineNumber++
            }
            this.movement.valueChain = valueChain
          }
        }
        this.reloadBuyback++
      },
      onOriginListingChange: function () {
        this.movement.OriginListingId = this.movement.OriginListing.listingId
        this.movement.OriginProductId = this.movement.OriginListing.productId
        this.reloadBuyback++
        this.reloadInventory++
      },
      onOriginCommitmentChange: function () {
        this.movement.OriginListingId = this.movement.OriginCommitment.groupId
        this.movement.CommitmentPartnerId = this.movement.OriginCommitment.partnerId
        this.movement.OriginProductId = this.movement.OriginCommitment.groupId
      },
      onChangeReload: function () {
        this.reloadBuyback++
        this.reloadInventory++
      },
    },
  }
</script>
